export const hpFeatureEnablementConfig = {
  'hosting-platform-core-self-service': [
    'PRO-340',
    'PRO-228',
    'PRO-157',
    'PRO-158',
    'PRO-7',
  ],
  'hosting-platform-core-self-service-admins': [
    'PRO-340',
    'PRO-228',
    'PRO-157',
    'PRO-158',
    'arla.encanto',
    'elldell.centeno',
    'juliuz.llanillo',
    'marilag.dimatulac',
    'reniel.lumban',
  ],
};
